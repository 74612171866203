import Video from './Video.js';
import PropTypes from 'prop-types';
import NameInserts from '../name_inserts/NameInserts.js';
import React, { Fragment } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Button from '../generic/Button.js';
import Icon from '../generic/Icon.js';
import classNames from 'classnames';
import I18n from 'i18n-js';

const useStyles = makeStyles((theme) => ({
  row: {
    paddingBlock: theme.spacing(1),
  },
  icon: {
    fill: theme.palette.secondary.contrastText,
  },
}));

const VideoStream = (props) => {
  const classes = useStyles();

  if (!props.visible || !props.stream) {
    return null;
  }

  const togglePip = () => {
    props.onEvent({ type: 'toggle_pip' });
  };

  return (
    <Fragment>
      {props.withNameInsert && <NameInserts {...props} />}
      <div
        className={classNames('video-stream-container', { pip: props.isPip })}
      >
        <Video {...props} />
        {props.isPip && (
          <div id="pip-overlay">
            <Typography variant="h6" className={classes.row}>
              {I18n.t('pip_mode_text:header')}
            </Typography>
            <Typography variant="body2" className={classes.row}>
              {I18n.t('pip_mode_text:description')}
            </Typography>
            {props.environment.canPip && (
              <div className={classes.row}>
                <Button onClick={togglePip}>
                  <Icon type="pip_off" className={classes.icon}></Icon>
                  {I18n.t('pip_mode_text:back_button')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

VideoStream.propTypes = {
  muted: PropTypes.bool,
  poster: PropTypes.string,
  stream: PropTypes.object,
  sinkId: PropTypes.string,
  visible: PropTypes.bool,
  onEvent: PropTypes.func,
  controls: PropTypes.bool,
  playback: PropTypes.object,
  className: PropTypes.string,
  withNameInsert: PropTypes.bool,
};

VideoStream.defaultProps = {
  muted: true,
  visible: true,
  sinkId: '',
  playback: {},
  withNameInsert: true,
};

export default VideoStream;
