import I18n from 'i18n-js';
import twemoji from 'twemoji';
import { getSearchParam } from './UrlHelpers.js';
import pluralRules from '../locale/pluralRules.js';
import NumberFormat from '../utils/NumberFormatHelper.js';
import EnTranslation from '../locale/en.json';
import DeTranslation from '../locale/de.json';
import FrTranslation from '../locale/fr.json';
import RuTranslation from '../locale/ru.json';
import EsTranslation from '../locale/es.json';
import PtTranslation from '../locale/pt.json';

export const supportedLocales = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  ru: 'Русский',
};

/**
 * AppConfig wraps around process.env and holds all application variables.
 *
 * NOTE: On changes for the AppConfig think about not to use the new context
 * feature of react by breaking down the configuration into smaller context
 * parts and providing their value. @see https://reactjs.org/docs/context.html
 **/
class AppConfig {
  constructor(env, eyeson) {
    this.env = env;
    this.eyeson = eyeson;
    this.init();
  }

  init() {
    if (
      process.env.NODE_ENV !== 'test' &&
      window.location.hostname !== 'localhost'
    ) {
    }

    if (process.env.REACT_APP_COM_API) {
      let api = process.env.REACT_APP_COM_API || this.eyeson.config.api;
      if (process.env.REACT_APP_COM_API === 'meta') {
        const meta = document.querySelector('meta[name="api-host"]');
        if (meta) {
          api = meta.getAttribute('content');
        }
      }
      this.eyeson.config.api = api;
    }

    this.initLocale();

    this.eyeson.config.giphy = { key: process.env.REACT_APP_GIPHY_KEY };
    twemoji.base = './images/twemoji/';
  }

  initLocale() {
    I18n.translations['en'] = EnTranslation;
    I18n.translations['de'] = DeTranslation;
    I18n.translations['fr'] = FrTranslation;
    I18n.translations['ru'] = RuTranslation;
    I18n.translations['es'] = EsTranslation;
    I18n.translations['pt'] = PtTranslation;
    I18n.pluralization['de'] = pluralRules['de'];
    I18n.pluralization['fr'] = pluralRules['fr'];
    I18n.pluralization['ru'] = pluralRules['ru'];
    I18n.pluralization['es'] = pluralRules['es'];
    I18n.pluralization['pt'] = pluralRules['pt'];

    I18n.defaultLocale = 'en';
    I18n.fallbacks = true;

    // locale only used on QuickJoin page otherwise overridden in setLocale
    I18n.locale = getSearchParam('locale') || this.detectBrowserLocale();
    NumberFormat.setLocale(I18n.locale);
  }

  get destinations() {
    return {
      exit: process.env.REACT_APP_EXIT_URL,
      logging: process.env.REACT_APP_LOGGING_ENDPOINT,
      inactivity: process.env.REACT_APP_INACTIVITY_URL,
      upgradeUrl: process.env.REACT_APP_UPGRADE_URL,
      accountsApi: process.env.REACT_APP_ACCOUNTS_API,
    };
  }

  get times() {
    return {
      room: parseFloat(process.env.REACT_APP_INACTIVITY_TIMER_ROOM, 10),
      dialog: parseFloat(process.env.REACT_APP_COUNTDOWN_DIALOG_TIME, 10),
      preview: parseFloat(process.env.REACT_APP_INACTIVITY_TIMER_PREVIEW, 10),
    };
  }

  get loggingActive() {
    return process.env.REACT_APP_LOGGING_ACTIVE === 'true';
  }

  get loggingDebug() {
    return process.env.REACT_APP_LOGGING_DEBUG === 'true';
  }

  get experimentalFeatures() {
    return ['development', 'integration'].includes(
      process.env.REACT_APP_RELEASE_STAGE
    );
  }

  setLocale(options) {
    if (!options.custom_fields) {
      return;
    }
    const {
      custom_fields: { locale = this.detectBrowserLocale() },
    } = options;
    I18n.locale = locale;
    NumberFormat.setLocale(I18n.locale);
  }

  detectBrowserLocale() {
    if (!!navigator.languages) {
      for (let i = 0, n = navigator.languages.length, locale; i < n; i++) {
        locale = navigator.languages[i].split('-')[0];
        if (locale === I18n.defaultLocale || !!I18n.translations[locale]) {
          return locale;
        }
      }
    }
    if (!!navigator.language) {
      let locale = navigator.language.split('-')[0];
      if (!!I18n.translations[locale]) {
        return locale;
      }
    }
    return I18n.defaultLocale;
  }
}

export default AppConfig;
