import { Logger } from 'eyeson';

let searchParamSupported = window.URL && 'searchParams' in URL.prototype;

function decodeUriComponentWithSpace(component) {
  return decodeURIComponent(component.replace(/\+/g, '%20'));
}

function getSearchParam(param) {
  if (searchParamSupported) {
    return new URL(window.location.href).searchParams.get(param);
  }
  let params = window.location.search.substr(1).split('&');
  for (let i = 0, n = params.length, entry; i < n; i++) {
    entry = params[i].split('=');
    if (decodeUriComponentWithSpace(entry[0]) === param) {
      return decodeUriComponentWithSpace(entry[1]);
    }
  }
  return null;
}

// Example url:
// http://localhost:3000/?NY6hFfKfuggrehFhzLNr6IX9
// http://localhost:3000/?NY6hFfKfuggrehFhzLNr6IX9&fbcli=12312313
//
// example token:
// NY6hFfKfuggrehFhzLNr6IX9
function getRoomToken() {
  const token = window.location.search.substring(1).split('&')[0];
  return token || 'missing-token';
}

// Example url:
// http://localhost:3000/?guest=0rQiWQKaOM1NeVf9tpifjlRt
// http://localhost:3000/?guest=0rQiWQKaOM1NeVf9tpifjlRt&fbcli=123123
//
// example token:
// 0rQiWQKaOM1NeVf9tpifjlRt
function getGuestToken() {
  const token = getSearchParam('guest');
  return token || 'missing-guest-token';
}

function guestNamesSuggested() {
  const param = getSearchParam('suggest');
  if (param === 'false') {
    return false;
  }
  return true;
}

function updatePermalinkUrl(roomData) {
  try {
    if (window.location.pathname.includes('/permalink')) {
      const { access_key } = roomData;
      const permalinkToken = getRoomToken();
      const url = window.location.href
        .replace(/\/permalink\/?/, '/')
        .replace(permalinkToken, access_key);
      window.history.replaceState(null, '', url);
    }
  } catch (error) {
    Logger.warn('UrlHelpers::updatePermalinkUrl', error);
  }
}

function getBasePath() {
  const meta = document.querySelector('meta[name="base-path"]');
  if (meta) {
    return meta.getAttribute('content');
  }
  return '/';
}

const allowedPaths = ['', 'permalink', 'youtube-popup'];

const isPathAllowed = () => {
  const basePath = getBasePath();
  const { pathname } = window.location;
  return allowedPaths.some((allowedPath) => {
    return basePath + allowedPath === pathname;
  });
};

export {
  getSearchParam,
  getRoomToken,
  getGuestToken,
  guestNamesSuggested,
  updatePermalinkUrl,
  getBasePath,
  isPathAllowed,
};
